import React, { Fragment } from 'react';
import Version from '../components/Version';

const Layout = (props) => {
  return (
    <AppWithoutAuthentication {...props} />
  );
}

const AppWithoutAuthentication = ({ children, seo }) => (
  <Fragment>
    <Version />
    {children}
    <Version />
  </Fragment>
);

export default Layout;
