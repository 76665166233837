import React from 'react';
import loadable from '@loadable/component';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

const NAVIGATION_HEIGHT = 84;

const Spacer = () => (
  <div style={{ height: NAVIGATION_HEIGHT, content: '' }} />
);

const filter = entry => entry && Object.keys(entry).length > 0;

const componentRenderer = (props, options = { exclude: [] }) => {
  if (props.pageContext.content === null) return [];
  const included = entry =>
    options.exclude.length === 0 ||
    options.exclude.find(e => e !== entry.type);
  const filteredContent = props.pageContext.contentIds
    .filter(filter)
    .filter(included);
  const isHero = entry =>
    entry.type.toLowerCase().includes('componenthero');

  const components = filteredContent.map(entry => {
    const Component = loadable(() =>
      import(`../ContenfulComponents/${entry.type}`),
    );
    return <Component {...props} key={entry.id} id={entry.id} />;
  });
  /*
    here we check if the component is a Hero we ignore the top margin for Navigation
  */
  const withTopMargin =
    filteredContent.length && isHero(filteredContent[0])
      ? components
      : [<Spacer key="spacer" />].concat(components);

  return withTopMargin;
};

const renderComponentName = (type, props) => {
  if (
    props.pageContext.contentIds === null ||
    props.pageContext.contentIds === []
  )
    return [];
  const entry = props.pageContext.contentIds
    .filter(filter)
    .find(entry => entry.type === type);
  if (entry) {
    const Component = loadable(() =>
      import(`../ContenfulComponents/${entry.type}`),
    );
    return (
      <Component
        {...props}
        data={entry}
        key={entry.id}
        id={entry.id}
      />
    );
  }
  return null;
};

const Page = props => {
  const seo = props.pageContext.seo;
  return (
    <Layout>
      <SEO {...seo} />
      {renderComponentName(
        'ContentfulComponentTopBarNavigation',
        props,
      )}
      {componentRenderer(props, {
        exclude: ['ContentfulComponentTopBarNavigation'],
      })}
      {renderComponentName('footer', props)}
    </Layout>
  );
};

export default Page;
