import React from 'react';
import { Helmet } from 'react-helmet';

const SEO = ({ siteUrl, title, description, image, pathname }) => {
  const seo = {
    title: title,
    description: (description && description.description) || '',
    image: image.fixed.src,
    url: `${siteUrl}${pathname || '/'}`,
  };

  return (
    <React.Fragment>
      <Helmet htmlAttributes={{ lang : 'en' }} title={seo.title} titleTemplate={seo.title}>
        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.image} />
        {seo.url && <meta property="og:url" content={seo.url} />}
        {seo.title && (
          <meta property="og:title" content={seo.title} />
        )}
        {seo.description && (
          <meta
            property="og:description"
            content={seo.description}
          />
        )}
        {seo.image && (
          <meta property="og:image" content={seo.image} />
        )}
      </Helmet>
    </React.Fragment>
  );
};

export default SEO;
